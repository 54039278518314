$(document).ready(function() {

    var mapData = $('#map-data');

    if(mapData.length > 0) {

        var mapConfiguration = {};
        var iteration = 0;

        $('#map-data .group-map').each(function() {

            var category = $(this);
            var categoryName = category.data('name');
            var categoryPoints = category.find('.point');

            categoryPoints.each(function(it) {

                var point = $(this);
                var pointName = point.data('name');
                var latitude = point.data('latitude');
                var longitude = point.data('longitude');
                var marker = point.data('marker');
                var markerType = point.data('marker-type');

                mapConfiguration[iteration] = {
                    'categoryName': categoryName,
                    'pointName': pointName,
                    'lat': latitude,
                    'lon': longitude,
                    'marker': marker,
                    'markerType': markerType
                }

                iteration++;
            });
        });

        var lat = $("#map-data").data("latitude");
        var lon = $("#map-data").data("longitude");
        var zoom = $("#map-data").data("zoom") ? $("#map-data").data("zoom") : 11;
        console.log(zoom);
        var macarte = null;
        var markerClusters;

        initMap(mapConfiguration);
    }

    function initMap(mapConfiguration) {

        macarte = L.map('map').setView([lat, lon], zoom);
        // markerClusters = L.markerClusterGroup();

        L.tileLayer('https://{s}.tile.openstreetmap.se/hydda/full/{z}/{x}/{y}.png', {
            minZoom: 11,
            maxZoom: 20
        }).addTo(macarte);

        for (var index in mapConfiguration) {

            var config = mapConfiguration[index];

            var myIcon = L.icon({
                iconUrl: config.marker,
                iconSize: [30, 30],
                // iconAnchor: [-25, 100],
                className: "marker-"+config.markerType,
                popupAnchor: [-3, -76]
            });

            // Remove if markerClusters
            var marker = L.marker([config.lat, config.lon], { icon: myIcon }).addTo(macarte);
            marker.bindPopup(config.pointName);

            // markerClusters
            // var marker = L.marker([config.lat, config.lon], { icon: myIcon });
            // marker.bindPopup(config.pointName);
            // markerClusters.addLayer(marker);
        }

        // markerClusters
        // macarte.addLayer(markerClusters);
    }


});

$('body').on('click', '.marker-select', function(e) {

    e.preventDefault();

    var el = $(this);
    var markerClass = el.data('markeur');
    var isVisible = el.attr('data-visible');

    if(isVisible == 1) {
        $('body').find('.'+markerClass).addClass('d-none');
        el.attr('data-visible', 0);
        el.removeClass('active');
    }
    else {
        $('body').find('.'+markerClass).removeClass('d-none');
        el.attr('data-visible', 1);
        el.addClass('active');
    }
});